<template>
  <div class="modal container-editor is-active">
    <div class="modal-background"></div>
    <ValidationObserver tag="div" ref="containerForm">
      <form class="modal-card" @submit.prevent="handleSubmit(submitContainer)">
        <header class="modal-card-head">
          <p :id="`modal-title-${container.articleId}`" class="modal-card-title" v-html="containerEditorHeading"></p>
        </header>

        <section class="modal-card-body">
          <fieldset :disabled="isContainerEditorLocked">
            <div class="columns is-mobile mb-1">
              <!-- XML file is only required if the container doesn't already have one -->

              <FileInput
                id="xmlFilePath"
                class="column is-half"
                fileType="xml"
                label="XML"
                :fileSizeLimit="512 * 1024"
                :filePath="container.xmlFilePath"
                :rules="!container.xmlFilePath ? 'required' : ''"
                @update="updateFile"
              />
              <FileInput
                id="pngFilePath"
                class="column is-half"
                fileType="png"
                label="Image"
                :fileSizeLimit="1024 * 1024"
                :filePath="container.pngFilePath"
                :isReadOnly="isMonographReadOnly"
                @update="updateFile"
              />
            </div>

            <div class="columns is-mobile mb-4">
              <FileInput
                id="molFilePath"
                class="column is-half"
                fileType="mol"
                label="MOL"
                :fileSizeLimit="512 * 1024"
                :filePath="container.molFilePath"
                :isReadOnly="isMonographReadOnly"
                @update="updateFile"
              />

              <FileInput
                id="cdxFilePath"
                class="column is-half"
                fileType="cdx"
                label="CDX"
                :fileSizeLimit="512 * 1024"
                :filePath="container.cdxFilePath"
                :isReadOnly="isMonographReadOnly"
                @update="updateFile"
              />
            </div>

            <TextArea id="justification" v-model="justification" :disabled="!isNewContainer" :label="`Justification${isNewContainer ? ' (optional)' : ''}`" />

            <!-- Store server-side errors here -->
            <ValidationProvider vid="saveError" />
          </fieldset>
        </section>
      </form>
    </ValidationObserver>

    <ValidationObserver tag="div" ref="containerForm" v-slot="{ handleSubmit, errors }">
      <form class="modal-card" @submit.prevent="handleSubmit(submitContainer)">
        <footer class="modal-card-foot">
          <button
            :id="`save-${container.articleId}`"
            v-if="!isMonographReadOnly"
            type="submit"
            class="button is-success footer-buttons-01"
            :class="{ 'is-loading': isContainerEditorSaving }"
            @submit.prevent="handleSubmit(submitContainer)"
          >
            Save
          </button>

          <button
            :id="`cancel-${container.articleId}`"
            type="button"
            class="button footer-buttons-01"
            @click="$emit('close')"
            :class="{ 'is-loading': isContainerEditorSaving }"
          >
            {{ isMonographReadOnly ? "Close" : "Cancel" }}
          </button>

          <span v-if="errors.saveError && errors.saveError.length" class="has-text-danger has-text-weight-bold">
            Failed to save container: {{ errors.saveError[0] }}
          </span>
        </footer>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { MerckIndexDataService } from "@/api";
import { FileInput, TextArea } from "@/components/forms";
import { v4 as uuidv4 } from "uuid";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "ContainerEditor",
  props: {
    container: {
      type: Object,
      required: true,
    },
    isMonographReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      justification: this.container.justification ?? "",
      // Stores new or replacement files prior to submission
      containerFiles: { xml: null, png: null, mol: null, cdx: null },
      isContainerEditorSaving: false,
      isMonographPreviewVisible: false,
    };
  },
  // To set an event listener of confirm method to delete (png, cdx, mol) file
  mounted() {
    this.$root.$on("confirm", (type) => {
      return this.confirm(type);
    });
  },
  // remove event listener of confirm method
  beforeDestroy() {
    this.$root.$off("confirm");
  },
  computed: {
    isNewContainer() {
      return !this.container.recordId;
    },
    containerEditorHeading() {
      return this.isNewContainer
        ? "Add container"
        : `${!this.isMonographReadOnly ? "Edit" : ""} ${this.container.title} ${this.isMonographReadOnly ? "(read-only)" : ""}`;
    },
    isContainerEditorLocked() {
      return this.isContainerEditorSaving || this.isMonographReadOnly;
    },
    fileButtonLabelDelete() {
      return `${"Delete"}`;
    },
  },
  methods: {
    hideDeleteContainerPrompt() {
      this.containerDeleteError = null;
      this.isContainerDeleting = false;
      this.isDeleteContainerPromptVisible = false;
    },
    async updateFile(type, file) {
      this.containerFiles[type] = file;
    },
    async submitContainer() {
      this.isContainerEditorSaving = true;
      let formData = new FormData();

      // Prepare the container data and add it to the form submission
      const containerData = JSON.stringify({
        articleId: this.container.articleId,
        // Only include justification with new containers, as it's immutable
        justification: this.isNewContainer ? this.justification.trim() : null,
        // Provide a new ID for new containers
        recordId: this.container.recordId ?? uuidv4(),
      });

      this.container["pngFilePath"] == null ? MerckIndexDataService.DeleteImageSubstance(this.container.recordId) : "";
      this.container["molFilePath"] == null ? MerckIndexDataService.DeleteMolFile(this.container.recordId) : "";
      this.container["cdxFilePath"] == null ? MerckIndexDataService.DeleteCdxFile(this.container.recordId) : "";

      formData.append("container", containerData);

      // Add any new or replacement files to the form submission
      for (const [type, file] of Object.entries(this.containerFiles)) {
        if (file) {
          formData.append(`${type}File`, file);
        }
      }

      // Submit the container data and any files
      const result = await MerckIndexDataService.addOrUpdateContainer(formData);
      if (result.Data && result.Data.recordId) {
        this.$emit("refresh");

        this.$emit("close");
      } else {
        // TODO: Error handling in the UI
        this.isContainerEditorSaving = false;
      }
    },

    async confirm(type) {
      await this.$confirm(`Are you sure you want delete the ${type} file?`)
        .then(() => {
          this.containerFiles[type] = null;
          // eslint-disable-next-line
          this.container[`${type}FilePath`] = null;
        })
        .catch(() => console.log(`the cancel button was clicked`));
    },
  },
  components: {
    FileInput,
    TextArea,
    ValidationObserver,
    ValidationProvider,
  },
};
</script>
